// Import mixins
@import '../mixins.scss';

.ant-input {
  // border-radius: 2px;
  &:hover,
  &:focus,
  &:active {
    // border-color: $primary;
  }
}

.ant-input-affix-wrapper {
    // border-radius: 2px;
  &:hover,
  &:focus,
  &:active {
    .ant-input:not(.ant-input-disabled) {
      border-color: $primary;
    }
  }
}
