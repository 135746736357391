.otp-container{
    padding: 10px 10px;
    text-align: center;
}

.otp-input{
    height: 40px;
    width: 40px;
    font-size:18px;
    border-radius: 4px;
    text-align: center;
    border: 1px solid #4F4F504D;
}

.otp-input:focus{
    outline-color: #01549A;
}

