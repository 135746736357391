.th-mobile-sidebar{
    background: #2a3649;
}

/* .th-mobile-sidebar .ant-drawer .ant-drawer-body{
    padding: 0 !important;
} */

.th-mobile-sidebar .ant-drawer-wrapper-body .ant-drawer-body{
    padding: 0 !important;
}

.ant-layout .ant-layout-sider-trigger{
    background:#2a3649 !important ;
}

