body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.th-hidden-scrollbar::-webkit-scrollbar{
  display: none;
}

.th-grey-card{
  border: 1px solid #dfdfdf;
  background-color: #F5F5F5;
}


.slick-slider .slick-dots{
  bottom: 200px;
}

.slick-slider .slick-dots  li button::before {
  font-size: 20px;

}


.slick-slider .slick-dots  li.slick-active button::before {
  color: #01549A;
  opacity: 1;

}


.blinking-border {
  border: 2px solid red; /* Red border */
  animation: blink 1s infinite; /* Animation with 1s duration, infinite loop */
}

@keyframes blink {
  0% {
    border-color: red;
  }
  50% {
    border-color: transparent;
  }
  100% {
    border-color: red;
  }
}


.custom-upload .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select{
  width: 100%;
  height: 300px;
}

/* Custom cursor styles */
.pencil-cursor {
  cursor: url("data:image/svg+xml;charset=utf-8,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23a)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.939 18.93a1 1 0 0 0 1.131 1.13l4.29-.613a3 3 0 0 0 1.697-.848l9.428-9.428a3 3 0 0 0 0-4.243l-1.414-1.414a3 3 0 0 0-4.243 0L5.4 12.943a3 3 0 0 0-.848 1.697l-.613 4.29Z' fill='%23fff'/%3E%3Cpath d='m4.929 19.071 2.475-.354-2.121-2.12-.354 2.474Zm.531-3.712 3.18 3.181.578-.083a2 2 0 0 0 1.131-.566l9.428-9.428a1.99 1.99 0 0 0 .537-.973 1.99 1.99 0 0 0-.537-1.855l-1.414-1.414a2 2 0 0 0-2.828 0L6.107 13.65a2 2 0 0 0-.565 1.131l-.083.578Z' fill='currentColor'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='a' x='-2' y='-1' width='28' height='28' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset dy='1'/%3E%3CfeGaussianBlur stdDeviation='1'/%3E%3CfeComposite in2='hardAlpha' operator='out'/%3E%3CfeColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0'/%3E%3CfeBlend in2='BackgroundImageFix' result='effect1_dropShadow_3181_2634'/%3E%3CfeBlend in='SourceGraphic' in2='effect1_dropShadow_3181_2634' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E") 2 20, auto;
}

.eraser-cursor {
  cursor: url("data:image/svg+xml;charset=utf-8,%3Csvg width='26' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23a)'%3E%3Cpath d='m17.95 2.808 4.242 4.242a3 3 0 0 1 0 4.243l-6.535 6.535A4 4 0 0 1 12.828 19H7.515a4 4 0 0 1-2.829-1.172l-.878-.878a3 3 0 0 1 0-4.243l9.9-9.9a3 3 0 0 1 4.242 0Z' fill='currentColor'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='m21.485 10.586-4.596 4.596-7.071-7.071 4.596-4.596a1.995 1.995 0 0 1 2.829 0l4.242 4.242a1.988 1.988 0 0 1 .577 1.606 1.995 1.995 0 0 1-.577 1.223Zm-5.303 5.303L9.11 8.818l-4.596 4.596a2 2 0 0 0 0 2.829l.878.878A3 3 0 0 0 7.515 18h5.313a3 3 0 0 0 2.122-.879' fill='%23fff'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='a' x='-1' y='-1' width='28' height='28' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset dy='1'/%3E%3CfeGaussianBlur stdDeviation='1'/%3E%3CfeComposite in2='hardAlpha' operator='out'/%3E%3CfeColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0'/%3E%3CfeBlend in2='BackgroundImageFix' result='effect1_dropShadow_3181_2633'/%3E%3CfeBlend in='SourceGraphic' in2='effect1_dropShadow_3181_2633' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E") 12 12, auto;
}

