@import '../mixins.scss';

.ant-table {
//   background: transparent !important;
}
.ant-table-wrapper .ant-table-thead >tr>th{
    color: #fff;
    background: $primary;
}

.ant-table-wrapper .ant-table-container table>thead>tr:first-child >*:first-child{
    border-start-start-radius: 5px;
}

.ant-table-wrapper .ant-table-container table>thead>tr:first-child >*:last-child{
    border-start-end-radius: 5px;
}